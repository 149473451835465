import { Directive, OnInit, Optional } from '@angular/core';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { Platform } from '@angular/cdk/platform';

/**
 * Directive that globally disables all popovers on mobile devices.
 *
 * This directive automatically applies to all elements that use the `nz-popover` attribute.
 * On mobile devices (Android and iOS), it sets the `nzPopoverTrigger` property to `null`,
 * effectively disabling the popover functionality. This helps prevent unwanted popovers
 * that might interfere with the mobile user experience.
 *
 * **Usage:**
 * - Simply declare this directive in your module to enable its functionality.
 * - There's no need to add it manually to individual elements.
 * - To enable specific popovers on mobile devices, use the `EnablePopoverOnMobileDirective`.
 *
 * @example
 * ```html
 * <!-- Popover will be disabled on mobile devices -->
 * <button nz-button nz-popover [nzPopoverContent]="contentTemplate">
 *   Open Popover
 * </button>
 *
 * <ng-template #contentTemplate>
 *   <p>Popover Content</p>
 * </ng-template>
 * ```
 *
 * @see EnablePopoverOnMobileDirective
 */
@Directive({
    selector: '[nz-popover]',
})
export class GlobalPopoverDirective implements OnInit {
    constructor(@Optional() private nzPopoverDirective: NzPopoverDirective, private platform: Platform) {}

    ngOnInit(): void {
        if (this.nzPopoverDirective && (this.platform.ANDROID || this.platform.IOS)) {
            this.nzPopoverDirective.trigger = null;
        }
    }
}
