import { Directive, OnInit } from '@angular/core';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { Platform } from '@angular/cdk/platform';

/**
 * Directive that enables a popover on mobile devices with a click trigger.
 *
 * This directive is used to override the global behavior of disabling popovers on mobile devices (applied in `GlobalPopoverDirective`).
 * Apply it to any element with `nz-popover` to enable the popover when the element is clicked on mobile devices.
 *
 * @example
 * ```html
 * <button
 *   nz-button
 *   nz-popover
 *   enablePopoverOnMobile
 *   [nzPopoverContent]="contentTemplate"
 * >
 *   Open Popover
 * </button>
 *
 * <ng-template #contentTemplate>
 *   <p>Popover Content</p>
 * </ng-template>
 * ```
 *
 * @see GlobalPopoverDirective
 */
@Directive({
    selector: '[mobilePopover]',
})
export class MobilePopoverDirective implements OnInit {
    constructor(private nzPopoverDirective: NzPopoverDirective, private platform: Platform) {}

    ngOnInit(): void {
        if (this.platform.ANDROID || this.platform.IOS) {
            this.nzPopoverDirective.trigger = 'click';
        }
    }
}
