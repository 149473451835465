import { BehaviorSubject, firstValueFrom, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ConfirmRegistration } from '@pgis/auth/confirm-registration/confirm-registration.model';
import { Router } from '@angular/router';
import { ChangePass } from '@pgis/auth/change-pass/change-pass.model';
import { LocalStorageKeys } from '@pgis/shared/enums/local-storage-keys.enum';

export const AUTH_TOKEN_KEY = 'access_token';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public get decodedToken(): any {
        return this.jwtHelper.decodeToken(this.token);
    }

    public get token(): string {
        return localStorage.getItem(AUTH_TOKEN_KEY);
    }

    public currentToken: Observable<string>;

    private currentTokenSubject: BehaviorSubject<string>;

    public currentUsersubject = new BehaviorSubject<any>(null);

    constructor(private http: HttpClient,
        private jwtHelper: JwtHelperService,
        private router: Router) {
        this.currentTokenSubject = new BehaviorSubject<string>(this.token);
        this.currentToken = this.currentTokenSubject.asObservable();
    }

    public get currentUserChanges(): Observable<any> {
        return this.currentUsersubject.asObservable().pipe(filter(s => !!s));
    }

    public get currentTokenValue(): string {
        return this.currentTokenSubject.value;
    }

    public getUserId() {
        return this.decodedToken.nameid;
    }

    public get getUserDepartmentId(): number {
        return Number(this.decodedToken.userDepartmentId);
    }

    public get isContractorAdmin(): boolean {
        const roles: string[] = Array.isArray(this.decodedToken.roles) ? this.decodedToken.roles : [this.decodedToken.roles];
        return roles.some(r => r === 'contractor-admin');
    }

    public useHasRole(roles: string[]): boolean {
        if (!this.decodedToken) {
            return false;
        }
        const userRoles = this.decodedToken.roles || [];
        return userRoles.some(role => roles.includes(role));
    }

    public get loggedIn(): boolean {
        if (!this.token) {
            return false;
        }
        try {
            return !this.jwtHelper.isTokenExpired(this.token);
        } catch (ex) {
            return false;
        }
    }

    callRefreshToken(): Observable<any> {
        return this.http
            .post(`/api/v1/auth/refresh-token`, {
                token: this.token
            })
            .pipe(
                map((result: any) => {
                    this.setToken(result.data.token);
                    return result.data.token;
                }),
                catchError(e => {
                    localStorage.removeItem(AUTH_TOKEN_KEY);
                    localStorage.removeItem(LocalStorageKeys.TasksFilter);
                    this.currentTokenSubject.next(null);
                    this.router.navigate(['auth/login']);
                    return throwError(() => e);
                })
            );
    }

    async login(email: string, password: string): Promise<any> {
        const result = <any>await firstValueFrom(this.http
            .post('/api/v1/auth/login', { email: email, password: password }));

        if (result.data.token) {
            this.setToken(result.data.token);
        }
        return result;
    }

    async requestPassChange(email: string): Promise<void> {
        return await firstValueFrom(this.http.post<void>('/api/v1/auth/request-pass', { email }));
    }

    async resetPassword(data: ConfirmRegistration): Promise<void> {
        return await firstValueFrom(this.http.post<void>('/api/v1/auth/reset-pass', data));
    }

    async confirmRegistration(data: ConfirmRegistration): Promise<void> {
        return await firstValueFrom(this.http.post<void>('/api/v1/auth/confirm-registration', data));
    }

    async changePassword(data: ChangePass): Promise<void> {
        return await firstValueFrom(this.http.post<void>('/api/v1/auth/change-pass', data));
    }

    async signup(email: string, password: string, password2: string): Promise<any> {
        const result = <any>await firstValueFrom(this.http
            .post('/api/v1/auth/signup', { email: email, password: password, password2: password2 }));
        if (result.accessToken) {
            this.setToken(result.accessToken);
        }
        return result;
    }

    async logout(): Promise<void> {
        await this.http.delete('/api/v1/auth/sign-out').toPromise();
        localStorage.removeItem(AUTH_TOKEN_KEY);
        localStorage.removeItem(LocalStorageKeys.TasksFilter);
        this.currentTokenSubject.next(null);
    }

    public setToken(token: string) {
        localStorage.setItem(AUTH_TOKEN_KEY, token);
        this.currentTokenSubject.next(token);
    }
}
