import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderModule } from 'ngx-order-pipe';

import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { IconsProviderModule } from 'src/app/icons-provider.module';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzTabsModule } from 'ng-zorro-antd/tabs';

import { IsGrantedDirective, ValidationErrorDirective } from './directives';
import { ValidationErrorComponent } from './components';
import { MapComponent } from './components/map/map.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { GroupPipe } from './pipes/group.pipe';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { Ng2SmartTableModule } from 'ng2-smart-table';

import { StatusChangeCommentModalComponent } from './components/tasks/status-change-comment-modal/status-change-comment-modal.component';
import { TaskStatusHistoryModalComponent } from './components/tasks/task-status-history-modal/task-status-history-modal.component';
import { LayerSource, LineSourceService, PointSourceService, PolygonSourceService } from './components/map';
import { AddTaskFileModalComponent } from './components/tasks/add-task-file-modal/add-task-file-modal.component';
import { MultiSelectLayersModalComponent } from './components/map/multi-select-layers-modal/multi-select-layers-modal.component';
import { TaskFilesModalComponent } from './components/tasks/task-files-modal/task-files-modal.component';
import { GlobalPopoverDirective } from './directives/global-popover.directive';
import { MobilePopoverDirective } from './directives/mobile-popover.directive';

const MODULES = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    NgbModule,
    OrderModule,
    FontAwesomeModule,
    Ng2SmartTableModule,
];

const NG_ZORRO_MODULE_LIST = [
    NzFormModule,
    NzButtonModule,
    NzIconModule,
    NzSelectModule,
    NzInputModule,
    NzRadioModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzInputNumberModule,
    NzCheckboxModule,
    IconsProviderModule,
    NzSpinModule,
    NzLayoutModule,
    NzTableModule,
    NzAlertModule,
    NzPageHeaderModule,
    NzMenuModule,
    NzModalModule,
    NzTypographyModule,
    NzToolTipModule,
    NzMessageModule,
    NzCollapseModule,
    NzUploadModule,
    NzTagModule,
    NzPopoverModule,
    NzDividerModule,
    NzImageModule,
    NzAvatarModule,
    NzDropDownModule,
    NzDrawerModule,
    NzSwitchModule,
    NzCardModule,
    NzNotificationModule,
    NzCarouselModule,
    NzStatisticModule,
    NzTabsModule,
];

const PGIS_COMPONENTS = [
    ValidationErrorComponent,
    MapComponent,
    StatusChangeCommentModalComponent,
    TaskStatusHistoryModalComponent,
    AddTaskFileModalComponent,
    MultiSelectLayersModalComponent,
];

const PGIS_DIRECTIVES = [IsGrantedDirective, ValidationErrorDirective, GlobalPopoverDirective, MobilePopoverDirective];

const PGIS_PIPES = [GroupPipe];

@NgModule({
    imports: [...MODULES, ...NG_ZORRO_MODULE_LIST],

    declarations: [
        ...PGIS_DIRECTIVES,
        ...PGIS_COMPONENTS,
        ...PGIS_PIPES,
        TaskFilesModalComponent,
        GlobalPopoverDirective,
        MobilePopoverDirective,
    ],

    exports: [...MODULES, ...NG_ZORRO_MODULE_LIST, ...PGIS_DIRECTIVES, ...PGIS_COMPONENTS, ...PGIS_PIPES],

    providers: [
        { provide: LayerSource, useClass: PolygonSourceService, multi: true },
        { provide: LayerSource, useClass: LineSourceService, multi: true },
        { provide: LayerSource, useClass: PointSourceService, multi: true },
    ],
})
export class SharedModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas);
        library.addIconPacks(far);
        library.addIconPacks(fab);
    }
}
